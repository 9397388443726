import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next/TransWithoutContext";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Icon from "../icon";
import SocialNetworks from "../socialNetworks";
import Button from "../button";

export default function Slider({
  tNameSpace,
  image,
  host = "",
  hostUrl = "",
  hostTheme = "light",
  sliderTheme = "",
  rgbTheme = "",
  controlsTheme = "",
  buttonTheme = "",
  ctaTheme = "",
  ctaTextColor = "",
  hasCta = false,
  socialUrls = [],
  ctaSlide = 0,
  ctaHref,
  numSlides = 2,
  direction = "ltr",
}) {
  const { t, i18n, ready } = useTranslation(tNameSpace);
  const [activeSlide, setActiveSlide] = useState(0);
  const [prevActiveSlide, setPrevActiveSlide] = useState(0);

  if (!ready) return null;

  const handleSliderAction = (action) => {
    setPrevActiveSlide(activeSlide);
    if (action === "next") setActiveSlide((next) => next + 1);
    if (action === "prev") setActiveSlide((prev) => prev - 1);
  };

  const smallButtonClasses = `flex items-center justify-center w-[40px] h-[40px] tv:max-th:w-[32px] tv:max-th:h-[32px] bg-${sliderTheme}-0 flex-shrink-0 rounded-full`;

  const slides = Array.from(new Array(numSlides)).map((_, i) => {
    let cta = null;

    if (hasCta) {
      cta = (
        <div className="mt-[32px]">
          <Button variant="cta" className={`bg-${ctaTheme}-0 ${ctaTextColor}`} href={ctaHref}>
            {ctaSlide === 0
              ? t(`${host}.cta`)
              : t(`${host}.slides.${i - 1}.cta`)}
          </Button>
        </div>
      );
    }

    if (!i) {
      return (
        <div
          key={i}
          className={classNames(
            "text-satoshi-body-lg mh:text-satoshi-body-xxl tv:text-satoshi-body-lg th:text-satoshi-body-xl desktop:text-satoshi-body-xxl",
            activeSlide !== i && "hidden",
            prevActiveSlide === i + 1 && "slideInRight"
          )}
        >
          <Trans
            key={i}
            ns={tNameSpace}
            i18n={i18n}
            i18nKey={`${host}.description`}
            components={{ b: <strong /> }}
          />
          {ctaSlide === i && cta}
        </div>
      );
    }

    const titleKey = `${host}.slides.${i - 1}.title`;
    const title = t(`${host}.slides.${i - 1}.title`);

    return (
      <div
        key={i}
        className={classNames(
          activeSlide !== i && "hidden",
          prevActiveSlide === i + 1 && "slideInRight",
          prevActiveSlide === i - 1 && "slideInLeft"
        )}
      >
        {title !== titleKey && (
          <div className="text-satoshi-heading-xs mb-[12px] mh:max-tv:mb-[16px] mh:max-tv:text-satoshi-heading-md th:text-satoshi-heading-sm desktop:text-satoshi-heading-md">
            {title}
          </div>
        )}
        <div className="text-satoshi-body-md mh:max-tv:text-satoshi-body-lg desktop:text-satoshi-body-xl">
          <Trans
            i18nKey={`${host}.slides.${i - 1}.content`}
            ns={tNameSpace}
            i18n={i18n}
            components={{
              b: <strong />,
              ul: <ul />,
              s: <span />,
              li: <li className="flex gap-[10px] mb-[8px]" />,
            }}
          />
        </div>
        {ctaSlide === i + 1 && cta}
      </div>
    );
  });

  return (
    <section
      className={classNames(
        "bg-white shadow-subtle rounded-2xl w-full flex flex-col overflow-hidden",
        direction === "ltr" ? "tv:flex-row" : "tv:flex-row-reverse"
      )}
    >
      <div
        className={classNames(
          "w-full flex items-center justify-center rounded-lg overflow-hidden relative",
          "th:w-1/2 tv:min-h-[360px] desktop:max-h-[656px]"
        )}
      >
        <div
          style={{ backgroundImage: `url(${image})` }}
          className={classNames(
            "z-0 relative w-full h-full min-h-[300px] bg-cover bg-center",
            "mh:min-h-[400px] tv:min-h-[360px] th:min-h-[472px] default:min-h-[656px]"
          )}
        />
        {host && (
          <Link
            onClick={() => window.scroll(0, 0)}
            to={hostUrl}
            target={hostUrl.startsWith("http") ? "_blank" : "_self"}
            rel="noopener noreferrer"
          >
            <HostTag theme={hostTheme}>{host}</HostTag>
          </Link>
        )}
      </div>
      <div
        style={{ gridTemplateRows: "1fr min-content" }}
        className={classNames(
          "w-full grid grid-cols-1 grid-rows-2 text-black gap-[4px] p-[24px] overflow-hidden",
          "min-h-[400px] mh:min-h-[552px] tv:min-h-[360px]",
          "mh:max-tv:gap-[42px] mh:max-tv:p-[40px] tv:justify-between tv:min-w-[420px] th:w-1/2 th:p-[40px] desktop:min-h-[656px] desktop:p-[64px]"
        )}
      >
        <div className="w-full flex">{slides}</div>

        <div
          className={classNames(
            "flex",
            activeSlide === 0 && "justify-between",
            activeSlide !== 0 && "items-center gap-[16px]"
          )}
        >
          {activeSlide === 0 ? (
            <div className="flex gap-[20px] w-[144px] desktop:w-[176px]">
              <SocialNetworks
                iconsClassName="desktop:w-[32px] desktop:h-[32px]"
                gap="16px"
                networks={socialUrls}
              />
            </div>
          ) : (
            <div className="w-full flex gap-[20px] min-h-[44px] relative z-10 slideInLeft">
              <div className="flex items-center gap-[4px] w-full">
                {Array.from(new Array(slides.length - 1)).map((_, i) => (
                  <SlideControl
                    key={i}
                    controlsTheme={controlsTheme}
                    isActive={activeSlide === i + 1}
                  />
                ))}
              </div>
            </div>
          )}

          {activeSlide > 0 && (
            <div className="flex gap-[12px] justify-end relative z-20">
              <button
              style={{background: rgbTheme}}
                className={classNames(smallButtonClasses, "slideInLeft")}
                onClick={() => handleSliderAction("prev")}
              >
                <Icon
                  name="chevron_down"
                  className="rotate-90"
                  stroke={buttonTheme}
                  size="24px"
                />
              </button>
              <button
                className={classNames(
                  smallButtonClasses,
                  activeSlide === slides.length - 1 && "opacity-50",
                  "scale-[1.2] -translate-x-[4px] -translate-y-[2px]",
                  "mh:max-tv:scale-[1.6] tv:max-th:scale-[1.5] mh:-translate-x-[12px] mh:-translate-y-[10px] tv:-translate-x-[8px] tv:-translate-y-[2px]",
                  "th:-translate-x-[4px] desktop:scale-[1.6] desktop:-translate-x-[12px] desktop:-translate-y-[10px]",
                  "arrowScaleDown"
                  )}
                  style={{background: rgbTheme}}
                disabled={activeSlide === slides.length - 1}
                onClick={() => handleSliderAction("next")}
              >
                <Icon
                  name="chevron_down"
                  className="-rotate-90"
                  size="24px"
                  stroke={buttonTheme}
                />
              </button>
            </div>
          )}

          {activeSlide === 0 && (
            <div
              className={classNames(
                "w-[50px] flex-shrink-0 flex gap-[12px] justify-end",
                "mh:w-[215px] tv:w-[167px] desktop:w-[214px]"
              )}
            >
              <div
                className="cursor-pointer flex gap-[16px] tv:gap-[8px] items-center"
                onClick={() => handleSliderAction("next")}
              >
                <span
                  className={classNames(
                    "hidden font-satoshi font-bold",
                    "mh:inline-block mh:text-satoshi-heading-sm tv:max-desktop:font-bold tv:text-satoshi-ui-lg desktop:text-satoshi-heading-sm"
                  )}
                >
                  {t(`${host}.showMore`)}
                </span>
                <button
                  className={`flex items-center flex-shrink-0 justify-center w-[48px] h-[48px] text-2xl bg-${sliderTheme}-0 rounded-full mh:max-tv:w-[64px] mh:max-tv:h-[64px] desktop:w-[64px] desktop:h-[64px]`}
                  disabled={activeSlide === slides.length - 1}
                  style={{background: rgbTheme}}
                >
                  <Icon
                    name="chevron_down"
                    size="32px"
                    className={classNames(
                      "-rotate-90 mh:max-tv:w-[32px] mh:max-tv:h-[32px] desktop:w-[32px] desktop:h-[32px]"
                    )}
                    stroke={buttonTheme}
                  />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

const SlideControl = ({ isActive = false, controlsTheme }) => {
  return (
    <div
      style={{ transition: "opacity 0.15s ease-in" }}
      className={classNames(
        `w-full h-[5px] rounded-md ${controlsTheme}`,
        !isActive && "opacity-30"
      )}
    />
  );
};

const HostTag = ({ children, theme }) => {
  const textColor = theme === "light" ? "text-white" : "text-black";
  const backgroundColor =
    theme === "light" ? "bg-[#FCE9E933]" : "bg-[#FDF8E399]";

  return (
    <div
      className={classNames(
        textColor,
        backgroundColor,
        "absolute z-10 p-[12px] pt-[10px] rounded-lg font-bold left-[24px] bottom-[24px]"
      )}
    >
      {children}
    </div>
  );
};
