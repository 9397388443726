import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";

const LinkComponent = ({
  children,
  target,
  href,
  locale,
  skipLocaleHandling,
  ...rest
}) => {
  const query = useParams();
  const router = useLocation();
  const lang = locale || query.lang || "";

  if (href?.indexOf("http") === 0) skipLocaleHandling = true;
  if (lang && !skipLocaleHandling) {
    href = href
      ? `/${lang}${href}`
      : router.pathname.replace("[lang]", lang) + "/";
  }

  return (
    <Link to={href} target={target}>
      <span {...rest}>{children}</span>
    </Link>
  );
};

export default LinkComponent;
