export default function ImagePanel({
  image = "",
  title = "",
  content = null,
  modalButton = null,
}) {
  return (
    <section className="bg-white shadow-subtle rounded-2xl w-full flex flex-col tv:flex-row overflow-hidden">
      <div className="w-full flex items-center justify-center rounded-lg overflow-hidden relative tv:max-h-[472px] desktop:max-h-[656px]">
        {image && <img className="z-0 relative w-full" src={image} alt="" />}
      </div>
      <div className="w-full flex flex-col text-black gap-[26px] p-[24px] mh:p-[40px] mh:gap-[42px] tv:p-[24px] th:p-[40px] desktop:p-[64px] tv:max-desktop:justify-between">
        <div className="flex flex-col justify-between h-full">
          <div className="w-full">
            <h2 className="mb-[16px] font-satoshi th:mb-[24px] desktop:mb-[32px] text-satoshi-heading-sm mh:max-tv:text-satoshi-heading-md th:text-satoshi-heading-md desktop:text-satoshi-heading-lg">
              {title}
            </h2>
            <div className="font-satoshi text-satoshi-body-md mh:max-tv:text-satoshi-body-lg th:text-satoshi-body-xl desktop:text-satoshi-display-xs">
              {content}
            </div>
          </div>
          {modalButton && (
            <div className="w-full mt-[26px] mh:mt-[42px] desktop:mt-[34px]">
              {modalButton}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
