import homeBg from "../static/images/bg_home.jpg";
import Navbar from "../components/navbar";
import OurProjects from "../components/home/ourProjects";
import Partners from "../components/home/partners";
import CoffeAndCommunity from "../components/home/coffeAndCommunity";
import Footer from "../components/shared/footer";
import FixedBackground from "../components/shared/fixedBackground";
import { useEffect, useState } from "react";

export default function HomePage() {
  const [scrolledTop, setScrolledTop] = useState(false);
  useEffect(() => {
    if (!scrolledTop) {
      window.scrollTo(0, 0);
      setScrolledTop(true);
    }

    // eslint-disable-next-line
  }, []);
  return (
    <>
      <main className="relative overflow-auto">
        <FixedBackground image={homeBg} />
        <Navbar bgImage={homeBg} />
        <CoffeAndCommunity />
        <OurProjects />
        <Partners tNameSpace="home-page" withCoffeLovers />
      </main>
      <Footer />
    </>
  );
}
