import { useTranslation } from "react-i18next";
import { useState } from "react";
import { languages } from "../../i18n";
import classNames from "classnames";
import logo from "../../static/svg/rungie_logo.svg";
import Emoji from "../shared/emoji";
import LinkComponent from "../shared/link";
import StaticBackground from "../shared/fixedBackground/static";
import iconStyles from "./icon.module.css";

export default function ClosedNavbar({ currentRoute, toggleMenu, bgImage }) {
  const [openingMenu, setOpeningMenu] = useState(false);
  const { t, i18n, ready } = useTranslation("navbar");
  if (!ready) return null;

  const handleToggleMenu = () => {
    setOpeningMenu((prev) => !prev);
    setTimeout(() => {
      toggleMenu();
    }, 400);
  };

  return (
    <div className="max-w-[1440px] mx-auto p-[16px] mh:py-[32px] mh:px-[24px] th:px-[40px] desktop:px-[64px]">
      {openingMenu && <StaticBackground className="fadeIn" image={bgImage} />}
      <div className="inline-flex relative z-[60] items-start justify-start gap-[12px]">
        <LinkComponent href="/">
          <img
            className="w-[124px] h-[40px]"
            src={logo}
            alt="Rungie"
            width={468}
            height={151}
            draggable="false"
          />
        </LinkComponent>

        {currentRoute.showName !== false && (
          <div className="flex items-end gap-[8px]">
            <span className="font-kaisei text-black leading-none text-kaisei-menu-sm">
              {t(currentRoute.name)}
            </span>
            {currentRoute.emoji ? (
              <div className="self-center">
                <Emoji className="text-[32px]" symbol={currentRoute.emoji} />
              </div>
            ) : (
              <img alt="" width={32} height={34} src={currentRoute.image} />
            )}
          </div>
        )}
      </div>
      <nav className="fixed w-full max-w-[1440px] mx-auto left-0 right-0 top-0 z-50 mix-blend-difference">
        <div
          className={classNames(
            "flex justify-end h-[40px] box-content items-start relative z-20",
            "p-[16px] pb-0 mh:pt-[32px] mh:px-[24px] th:px-[40px] desktop:px-[64px]"
          )}
        >
          <div className="flex gap-[30px] mt-[8px]">
            <div className="hidden mh:flex text-white">
              {languages.map((lng) => (
                <LinkComponent
                  href={currentRoute.path}
                  key={lng}
                  locale={lng}
                  className={classNames(
                    "px-[8px] py-[4px] rounded-lg",
                    "font-satoshi text-satoshi-ui-lg hover:bg-white hover:bg-opacity-[0.06]",
                    lng === i18n.language && "font-bold"
                  )}
                >
                  {t(lng)}
                </LinkComponent>
              ))}
            </div>

            <div className="mh:w-[80px]">
              <button
                className="flex text-white items-center gap-[10px] w-full font-satoshi text-satoshi-ui-lg font-bold"
                onClick={handleToggleMenu}
              >
                <div className="hidden flex-grow mh:inline-block">
                  {t("menu")}
                </div>
                <div
                  className={classNames(
                    iconStyles.menuIcon,
                    openingMenu && iconStyles.active
                  )}
                >
                  <div className={iconStyles.bar} />
                </div>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
