import classNames from "classnames";

export default function Section({
  id,
  children,
  className,
  containerPaddings,
  background = "bg-transparent",
  ...props
}) {
  return (
    <article
      {...props}
      id={id}
      className={classNames("relative z-10", background, className)}
    >
      <div
        className={classNames(
          containerPaddings ||
            "max-w-[1440px] mx-auto p-[16px] mh:p-[24px] th:p-[40px] desktop:p-[64px]"
        )}
      >
        {children}
      </div>
    </article>
  );
}
