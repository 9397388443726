import { useTranslation } from "react-i18next";
import Emoji from "../../../components/shared/emoji";
import Icon from "../../../components/shared/icon";
import classNames from "classnames";

const colors = [
  { bgColor: "bg-green-0", buttonColor: "bg-green-10" },
  { bgColor: "bg-yellow-0", buttonColor: "bg-yellow-10" },
  { bgColor: "bg-red-0", buttonColor: "bg-red-10-primary" },
  { bgColor: "bg-blue-0", buttonColor: "bg-blue-10" },
];

export default function JobCard({ index, title, category, emoji, url }) {
  const { t, ready } = useTranslation("team-page");

  if (!ready) return null;

  const color = colors[index % 4];

  return (
    <div
      className={`rounded-2xl flex flex-col justify-between overflow-hidden ${color.bgColor}`}
    >
      <div className="p-[24px] text-black">
        <div className="flex flex-nowrap items-start justify-between">
          <div className="mb-[12px] font-extrabold text-satoshi-heading-md">
            {title}
          </div>
          <Emoji className="text-[24px]" symbol={emoji} />
        </div>
        <div className="text-satoshi-ui-lg">
          {t(`offers.categories.${category}`)}
        </div>
      </div>
      <a href={url} target="_blank" rel="noreferrer noopener">
        <button
          className={classNames(
            "text-surface-1 py-[16px] px-[24px] w-full flex items-center justify-between text-satoshi-ui-lg font-bold",
            color.buttonColor
          )}
        >
          <span>{t("offers.seeOffer")}</span>
          <div>
            <Icon name="linkedin" />
          </div>
        </button>
      </a>
    </div>
  );
}
