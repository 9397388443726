import { Trans } from "react-i18next/TransWithoutContext";
import { useTranslation } from "react-i18next";
import Section from "../../../components/shared/section";
import { Title, TitleSpan } from "../../../components/shared/title";
import { jobOffers } from "./constants";
import JobCard from "./jobCard";
import FadeInUp from "../../shared/animations/fadeInUp";

export default function JobOffers() {
  const { t, i18n, ready } = useTranslation("team-page");

  if (!ready) return null;

  return (
    <Section
      id="offers"
      className="text-white"
      background="bg-surface-0-primary"
    >
      <div className="mb-[64px] mt-[60px] mh:text-center tv:mt-[100px]">
        <FadeInUp>
          <Title className="mb-[64px] th:mb-[80px]">
            <Trans
              ns="team-page"
              i18n={i18n}
              i18nKey="offers.title"
              components={[<TitleSpan key="0">abiertas</TitleSpan>]}
            />
          </Title>
        </FadeInUp>
        <FadeInUp>
          <h2 className="mb-[24px] font-satoshi text-satoshi-heading-md th:text-satoshi-heading-lg">
            {t("offers.subtitle")}
          </h2>

          <div className="text-satoshi-body-md font-satoshi th:text-satoshi-body-lg th:max-w-[640px] th:mx-auto">
            <Trans
              ns="team-page"
              i18n={i18n}
              i18nKey="offers.content"
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              components={{ a: <a href="mailto:joinus@rungie.io" /> }}
            />
          </div>
        </FadeInUp>
      </div>

      <div className="flex flex-col gap-[24px] mb-[80px] grid-cols-3 tv:grid desktop:grid-cols-4">
        {jobOffers.map((offer, i) => (
          <FadeInUp key={i}>
            <JobCard index={i} {...offer} />
          </FadeInUp>
        ))}
      </div>
    </Section>
  );
}
