import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./styles/index.css";
import TokenPage from "./pages/token";
import TeamPage from "./pages/team";
import HomePage from "./pages";
import LanguagePath from "./LanguagePath";
import RedirectToLocale from "./redirectToLocale";

import "./i18n";

const NoMatch = () => {
  let navigate = useNavigate();
  React.useEffect(() => {
    navigate('/');
  }, [navigate]);

  return null;
};

// ...

const router = (
  <BrowserRouter basename="/">
    <Routes>
      <Route path="/" element={<RedirectToLocale />} />
      <Route path=":lang" element={<LanguagePath />}>
        <Route
          path=""
          element={
            <React.Suspense fallback={<h1>loading...</h1>}>
              <HomePage />
            </React.Suspense>
          }
        />
        <Route
          path="/:lang/team"
          element={
            <React.Suspense fallback={<h1>loading...</h1>}>
              <TeamPage />
            </React.Suspense>
          }
        />
        {/* <Route
          path="/:lang/token"
          element={
            <React.Suspense fallback={<h1>loading...</h1>}>
              <TokenPage />
            </React.Suspense>
          }
        /> */}
      </Route>
      <Route path="*" element={<NoMatch />} />
    </Routes>
  </BrowserRouter>
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>{router}</React.StrictMode>
);