import rungieCoin from "../../static/images/rungie_coin.png";

export const ROUTES = [
  {
    path: "/",
    name: "home",
    showName: false,
    activeColor: "text-orange-primary",
    hoverColor: "hover:text-orange-primary",
  },
/*   {
    path: "/token",
    name: "token",
    image: rungieCoin,
    activeColor: "text-blue-primary",
    hoverColor: "hover:text-blue-primary",
  }, */
  {
    path: "/team",
    name: "team",
    emoji: "👊",
    activeColor: "text-red-primary",
    hoverColor: "hover:text-red-primary",
  },

/*   {
    path: "https://rungie.io/",
    external: true,
    name: "blog",
    activeColor: "text-green-primary",
    hoverColor: "hover:text-green-primary",
  }, */
];
