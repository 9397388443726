import classNames from "classnames";
import { useInView } from "react-intersection-observer";

export default function FadeIn({ children }) {
  const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });
  return (
    <div
      ref={ref}
      className={classNames(inView && "fadeInSection", "opacity-0")}
    >
      {children}
    </div>
  );
}
