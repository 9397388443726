import Modal from "../../../components/shared/modal";
import { useTranslation } from "react-i18next";
import ModalList from "../../../components/shared/modal/list";

export default function SuperCommunityModal({ onClose }) {
  const { t, ready } = useTranslation("home-page");

  if (!ready) return null;

  return (
    <Modal
      title={t("panel-1.modal.title")}
      onClose={onClose}
      content={
        <ModalList
          items={[
            { content: t("panel-1.modal.items.0") },
            { content: t("panel-1.modal.items.1") },
          ]}
        />
      }
      closeButton={t("panel-1.modal.closeButton")}
    />
  );
}
