import { useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ROUTES } from "./constants";
import OpenedNavbar from "./openedNavbar";
import ClosedNavbar from "./closedNavbar";

export default function Navbar({ bgImage }) {
  const { lang } = useParams();
  const location = useLocation();
  const { pathname } = location;

  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = (action) => setMenuActive((prev) => !prev);

  const currentRoute =
    ROUTES.find((route) => `/${lang}${route.path}` === pathname) || {};

  return (
    <>
      {menuActive ? (
        <OpenedNavbar
          currentRoute={currentRoute}
          onClose={toggleMenu}
          lang={lang}
          bgImage={bgImage}
        />
      ) : (
        <ClosedNavbar
          currentRoute={currentRoute}
          toggleMenu={toggleMenu}
          lang={lang}
          bgImage={bgImage}
        />
      )}
      {menuActive && (
        <div className="max-w-[1440px] mx-auto p-[16px] mh:py-[32px] mh:px-[24px] th:px-[40px] desktop:px-[64px]">
          <div className="h-[45px]"></div>
        </div>
      )}
    </>
  );
}
