import JobOffers from "../components/team/offers";
import OurTeam from "../components/team/ourTeam";
import teamBg from "../static/images/bg_team.jpg";
import Navbar from "../components/navbar";
import Footer from "../components/shared/footer";
import FixedBackground from "../components/shared/fixedBackground";
import { useEffect, useState } from "react";

export default function TeamPage() {
  const [scrolledTop, setScrolledTop] = useState(false);
  useEffect(() => {
    if (!scrolledTop) {
      window.scrollTo(0, 0);
      setScrolledTop(true);
    }

    // eslint-disable-next-line
  }, []);
  return (
    <>
      <main className="relative overflow-auto">
        <FixedBackground image={teamBg} />
        <Navbar bgImage={teamBg} />
        <OurTeam />
        <JobOffers />
      </main>
      <Footer />
    </>
  );
}
