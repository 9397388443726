import { useInView } from "react-intersection-observer";
import Emoji from "../../../components/shared/emoji";
import SocialNetworks from "../../../components/shared/socialNetworks";

export default function TeamMember({
  name,
  position,
  emoji,
  emojiTheme,
  memoji,
  socialNetworks,
}) {
  const { ref, inView } = useInView({
    rootMargin: "100px 0px 0px 0px",
    triggerOnce: true,
  });
  return (
    <div
      ref={ref}
      className="flex flex-col bg-white rounded-2xl overflow-hidden h-full min-h-[260px] shadow-subtle mh:min-h-[310px] th:min-h-[372px] desktop:min-h-[310px]"
    >
      <div
        className="relative bg-no-repeat w-full h-[120px] mh:h-[150px] th:h-[200px]"
        style={{
          backgroundImage: inView && `url(${memoji})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute z-10 text-[16px] right-[8px] mh:right-[16px] th:right-[24px] bottom-0 translate-y-[50%] rounded-[10px] flex items-center justify-center">
          <Emoji
            symbol={emoji}
            padding="p-[8px]"
            className="mh:w-[40px] mh:h-[40px] mh:text-[20px] th:w-[48px] th:h-[48px] th:text-[26px]"
            bgColor={emojiTheme}
            withBorder
          />
        </div>
      </div>
      <div
        className="h-[3px] relative z-0"
        style={{
          opacity: 0.8,
          background:
            "radial-gradient(100% 779.61% at 0% 100%, #FFFFFF 0%, #FFFFFF 12.5%, #FFFFFF 25%, #F5D597 37.5%, #F0D991 50%, #99CFE4 62.5%, #FFFFFF 75%, #FFFFFF 87.5%, #FFFFFF 100%)",
        }}
      />
      <div className="p-[16px] th:p-[24px] th:pt-[22px] text-black flex-grow">
        <div className="h-full flex flex-col items-start justify-between">
          <div className="w-full">
            <div className="mb-[10px] font-satoshi text-satoshi-heading-xs mh:text-satoshi-heading-sm th:text-satoshi-heading-md">
              {name}
            </div>
            <div className="mb-[13px] th:mb-[40px] font-satoshi text-surface-1 text-satoshi-ui-md th:text-satoshi-ui-lg">
              {position}
            </div>
          </div>
          <div className="flex gap-[12px] items-center">
            <SocialNetworks networks={socialNetworks} gap="12px" size="24px" />
          </div>
        </div>
      </div>
    </div>
  );
}
