import mitoImage from "../../../static/images/projects/mito.png";
import overgieImage from "../../../static/images/projects/overgie.png";
import berrlyImage from "../../../static/images/projects/berrly.png";
import rungieImage from "../../../static/images/projects/rungie.png";

export const PROJECTS = [
  {
    image: overgieImage,
    host: "overgie.com",
    hostUrl: "https://overgie.com",
    hostTheme: "light",
    sliderTheme: "green",
    controlsTheme: "bg-green-max",
    buttonTheme: "#3E6329",
    ctaTheme: "green",
    ctaTextColor: "#3E6329",
    hasCta: false,
    ctaHref: "https://overgie.com",
    ctaSlide: 0,
    numSlides: 4,
    socialUrls: [
      { network: "instagram", url: "https://www.instagram.com/weareovergie/" },
      { network: "twitter", url: "https://twitter.com/weareovergie" },
    ],
  },
  {
    image: berrlyImage,
    host: "berrly.com",
    hostUrl: "https://berrly.com/",
    hostTheme: "light",
    sliderTheme: "purple",
    rgbTheme: "rgb(239 241 255)",
    controlsTheme: "bg-purple-primary",
    buttonTheme: "#4E43CE",
    ctaTheme: "purple",
    ctaTextColor: "text-purple-primary",
    hasCta: false,
    ctaHref: "https://berrly.com/",
    ctaSlide: 0,
    numSlides: 3,
    direction: "rtl",
    socialUrls: [
      { network: "instagram", url: "https://www.instagram.com/berrlycloud/" },
      { network: "linkedin", url: "https://www.linkedin.com/company/berrly/" },
    ],
  },
  {
    image: mitoImage,
    host: "mito.io",
    hostUrl: "https://mito.io/",
    hostTheme: "light",
    sliderTheme: "red",
    controlsTheme: "bg-red-max",
    buttonTheme: "#972400",
    ctaTheme: "red",
    ctaTextColor: "text-red-max",
    hasCta: true,
    ctaHref: "mailto:info@mito.io",
    ctaSlide: 0,
    numSlides: 3,
    socialUrls: [
      { network: "instagram", url: "https://www.instagram.com/mitogallery/" },
      { network: "twitter", url: "https://twitter.com/mitogallery" },
    ],
  },
  /* {
    image: rungieImage,
    host: "token.rungie.io",
    hostUrl: "/token",
    hostTheme: "dark",
    sliderTheme: "yellow",
    controlsTheme: "bg-orange-max",
    buttonTheme: "#EB8047",
    ctaTheme: "orange",
    ctaTextColor: "text-surface-0",
    hasCta: true,
    ctaSlide: 3,
    numSlides: 3,
    socialUrls: [
      { network: "twitter", url: "https://twitter.com/RungieLabs" },
      { network: "discord", url: "https://discord.gg/WzwZgdUJYy" },
      { network: "tiktok", url: "https://www.tiktok.com/@rungielabs" },
    ],
  }, */
];
