import Emoji from "../emoji";

export default function ModalList({ items = [] }) {
  return (
    <ul className="flex flex-col gap-[16px]">
      {items.map((item, i) => (
        <li
          key={i}
          className="flex gap-[10px] text-satoshi-body-md tv:text-satoshi-body-lg"
        >
          <span>
            <Emoji symbol={item.emoji || "👉"} />
          </span>
          <span>{item.content}</span>
        </li>
      ))}
    </ul>
  );
}
