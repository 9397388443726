import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useLocation } from "react-router-dom";

export default function LanguagePath() {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const curPath = location.pathname;

  useEffect(() => {
    if (lang && i18n.resolvedLanguage !== lang) {
      if (i18n.options?.supportedLngs.includes(lang)) {
        i18n.changeLanguage(lang);
      } else {
        navigate("/" + i18n.resolvedLanguage + curPath, { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [lang]);

  return <Outlet />;
}
