import classNames from "classnames";

const Emoji = ({
  symbol,
  label,
  bgColor = "",
  padding = "px-[12px] py-[12px]",
  withBorder = false,
  className,
}) => {
  const cx = classNames(
    className,
    bgColor && `${bgColor} ${padding}`,
    "rounded-lg leading-none mh:rounded-xl th:rounded-2xl text-center h-min font-satoshi"
  );
  return (
    <div
      style={withBorder ? { border: "1px solid #0000000D" } : {}}
      className={cx}
      role="img"
      aria-label={label ? label : ""}
      aria-hidden={label ? "false" : "true"}
    >
      <div>{symbol}</div>
    </div>
  );
};

export default Emoji;
