import classNames from "classnames";

export default function Button({
  children,
  href,
  className,
  size = "L",
  variant = "primary",
  ...props
}) {
  return href ? (
    <a href={href}>
      <button 
        {...props} 
        style={{
          transition: "transform ease 0.2s",
          ...(props.style || {}),
          paddingTop: "14px",
          paddingBottom: "16px"
        }}
        className={getClassNames(className, size, variant)}>
        {children}
      </button>
    </a>
  ) : (
    <button
      {...props}
      style={{
        transition: "transform ease 0.2s",
        ...(props.style || {}),
        paddingTop: "14px",
        paddingBottom: "16px"
      }}
      className={getClassNames(className, size, variant)}
    >
      {children}
    </button>
  );
}

const getClassNames = (className, size, variant) => {
  const common =
    "hover:translate-y-[-2px] border-none p-[12px] rounded-lg font-satoshi font-bold text-satoshi-ui-md th:text-satoshi-ui-lg";

  if (variant === "primary") {
    return classNames(
      "bg-surface-1 shadow-subtle text-white hover:shadow-hoverButton hover:bg-surface-3",
      common,
      className
    );
  }

  if (variant === "modal") {
    return classNames(
      "bg-orange-0 shadow-subtle text-black hover:bg-yellow-30-primary",
      common,
      className
    );
  }

  if (variant === "cta") return classNames(common, className);

  return classNames("bg-surface-10 text-black", common, className);
};
