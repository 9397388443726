import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { languages } from "../../i18n";
import { ROUTES } from "./constants";
import logoColored from "../../static/svg/rungie_logo_colored.svg";
import Icon from "../shared/icon";
import LinkComponent from "../shared/link";
import StaticBackground from "../shared/fixedBackground/static";
import { useState } from "react";

export default function OpenedNavbar({ currentRoute, lang, onClose, bgImage }) {
  const [fadeOut, setFadeOut] = useState(false);
  const { t, i18n, ready } = useTranslation("navbar");

  if (!ready) return null;

  const handleClose = () => {
    setFadeOut(true);
    setTimeout(() => onClose(), 100);
  };

  return (
    <>
      <StaticBackground image={bgImage} />
      <nav
        className={classNames(
          "fixed left-0 top-0 w-full z-50",
          fadeOut && "fadeOut"
        )}
      >
        <div
          style={{
            gridTemplateAreas: `'logo langs' 'logo nav'`,
            gridTemplateRows: "auto 1fr",
            gridTemplateColumns: "auto 1fr",
          }}
          className={classNames(
            "grid grid-cols-2 grid-rows-2 gap-[12px] max-w-[1440px] mx-auto p-[16px]",
            "mh:py-[32px] mh:px-[24px] th:px-[40px] desktop:px-[64px]"
          )}
        >
          <div style={{ gridArea: "logo" }} className="z-20 mh:fadeInRight">
            <div className="flex w-full h-full mh:items-center">
              <LinkComponent href="/">
                <img
                  className="w-[124px] h-[40px] mh:h-[60px] mh:w-[187px] th:w-[249px] th:h-[80px] desktop:w-[312px] desktop:h-[100px]"
                  src={logoColored}
                  alt="Rungie"
                />
              </LinkComponent>
            </div>
          </div>
          <div
            style={{ gridArea: "langs" }}
            className="z-20 flex justify-end items-center mix-blend-difference"
          >
            <div className="flex gap-[30px] mt-[8px]">
              <div className={classNames("flex text-white")}>
                {languages.map((lng) => (
                  <LinkComponent
                    href={currentRoute.path}
                    key={lng}
                    locale={lng}
                    className={classNames(
                      "px-[8px] py-[4px] rounded-lg",
                      "font-satoshi text-satoshi-ui-lg hover:bg-white hover:bg-opacity-[0.06]",
                      lng === i18n.language && "font-bold"
                    )}
                    onClick={handleClose}
                  >
                    {t(lng)}
                  </LinkComponent>
                ))}
              </div>

              <div className="mh:w-[80px]">
                <button
                  className="flex text-white items-center gap-[10px] w-full font-satoshi text-satoshi-ui-lg font-bold"
                  onClick={handleClose}
                >
                  <div className="hidden flex-grow mh:inline-block">
                    {t("close")}
                  </div>
                  <Icon name="close_menu" />
                </button>
              </div>
            </div>
          </div>

          <ul
            style={{ gridArea: "nav" }}
            className="z-20 text-right mr-[8px] mt-[20px] fadeInLeft"
          >
            {ROUTES.map((route) => {
              const isActive = currentRoute.path === route.path;
              return (
                <li
                  key={route.path}
                  onClick={handleClose}
                  className={classNames(
                    "h-auto leading-none mb-[4px]",
                    isActive
                      ? `${route.activeColor} font-kaisei text-[60px] tracking-tighter th:text-[76px]`
                      : "text-white"
                  )}
                >
                  <LinkComponent
                    href={route.path}
                    target={route.external ? "_blank" : "_self"}
                  >
                    <div className="relative">
                      <span
                        className={classNames(
                          !isActive &&
                            "hover:text-[60px] th:hover:text-[76px] desktop:hover:text-[90px] hover:font-kaisei hover:tracking-tighter",
                          "text-satoshi-display-md th:text-satoshi-display-lg desktop:text-satoshi-display-xl",
                          route.hoverColor
                        )}
                      >
                        {t(route.name)}
                      </span>
                      {route.external && (
                        <span className="absolute ml-[2px] left-full bottom-0 w-[16px] h-[16px] th:w-[20px] th:h-[20px] desktop:w-[24px] desktop:h-[24px]">
                          <Icon size="32px" name="external_link" />
                        </span>
                      )}
                    </div>
                  </LinkComponent>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="fadeIn bg-surface-primary fixed w-[100vw] h-[100vh] top-0 left-0 z-10" />
      </nav>
    </>
  );
}
