import classNames from "classnames";
import { useInView } from "react-intersection-observer";

export default function FadeInUp({ children }) {
  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true });
  return (
    <div
      ref={ref}
      className={classNames(inView && "fadeInUp", "fadeInUpInitial")}
    >
      {children}
    </div>
  );
}
