import { useTranslation } from "react-i18next";
import Modal from "../../../components/shared/modal";
import ModalList from "../../../components/shared/modal/list";

export default function CoffeLoversModal({ onClose }) {
  const { t, ready } = useTranslation("common");

  if (!ready) return null;

  return (
    <Modal
      onClose={onClose}
      title={t("coffe-lovers.modal.title")}
      content={
        <>
          <p className="mb-[16px]">{t("coffe-lovers.modal.content")}</p>
          <ModalList
            items={[
              { emoji: "☕️", content: t("coffe-lovers.modal.items.0") },
              { emoji: "🚀", content: t("coffe-lovers.modal.items.1") },
            ]}
          />
        </>
      }
      closeButton={t("coffe-lovers.modal.closeButton")}
    />
  );
}
