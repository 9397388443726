import Modal from "../../../components/shared/modal";
import { useTranslation } from "react-i18next";
import ModalList from "../../../components/shared/modal/list";

export default function TokenInfoModal({ onClose }) {
  const { t, ready } = useTranslation("home-page");

  if (!ready) return null;

  return (
    <Modal
      onClose={onClose}
      title={t("panel-2.modal.title")}
      content={
        <ModalList
          items={Array.from(new Array(6)).map((_, i) => ({
            content: t("panel-2.modal.items." + i),
          }))}
        />
      }
      closeButton={t("panel-2.modal.closeButton")}
    />
  );
}
