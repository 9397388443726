import classNames from "classnames";
import { Title } from "../title";
import { Trans } from "react-i18next/TransWithoutContext";

export default function SectionHero({ title, subtitle, content, cta = null }) {

  return (
    <div className="mt-[80px] tv:flex tv:items-end tv:justify-between">
      <div
        className={classNames(
          "max-w-[355px] tv:max-w-[450px] th:max-w-[642px]"
        )}
      >
        {title && (
          <Title className="mb-[24px] tv:mb-[32px] th:mb-[40px]">{title}</Title>
        )}
        {content && (
          <div className="font-satoshi text-satoshi-body-md th:text-satoshi-body-xl">
            <Trans
              components={{b: <strong />}}
            >
            {content}
            </Trans>
          </div>
        )}
      </div>
      {cta && <div className="mt-[32px]">{cta}</div>}
    </div>
  );
}
