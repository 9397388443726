import classNames from "classnames";
import externalLink from "../../../static/svg/icons/external_link.svg";
import discord from "../../../static/svg/icons/discord.svg";
import linkedin from "../../../static/svg/icons/linkedin.svg";
import tiktok from "../../../static/svg/icons/tiktok.svg";
import twitter from "../../../static/svg/icons/twitter.svg";
import medium from "../../../static/svg/icons/medium.svg";
import instagram from "../../../static/svg/icons/instagram.svg";
import close from "../../../static/svg/icons/close.svg";
import menu from "../../../static/svg/icons/menu.svg";
import closeMenu from "../../../static/svg/icons/close_menu.svg";

const ChevronDown = ({ stroke = "#061010", className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 9L12 15L18 9"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const iconsMap = {
  discord,
  linkedin,
  tiktok,
  twitter,
  medium,
  instagram,
  close,
  menu,
  close_menu: closeMenu,
  external_link: externalLink,
};

export default function Icon({ name, className, stroke, size = "", ...rest }) {
  if (name === "chevron_down") {
    return (
      <ChevronDown
        className={classNames(
          size ? `w-[${size}] h-[${size}]` : "w-[24px] h-[24px]",
          className
        )}
        stroke={stroke}
      />
    );
  }
  return (
    <img
      src={iconsMap[name]}
      alt={name}
      className={classNames(
        size ? `w-[${size}] h-[${size}]` : "w-[24px] h-[24px]",
        className
      )}
      {...rest}
    />
  );
}
