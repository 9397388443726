import classNames from "classnames";
import Button from "../button";
import Icon from "../icon";
import { useState } from "react";

export default function Modal({ title, content, closeButton, onClose }) {
  const [shouldSlideOff, setShouldSlideOff] = useState(false);
  const handleClose = () => {
    setShouldSlideOff(true);
    setTimeout(() => onClose(), 300);
  };
  return (
    <div className="fixed bottom-0 left-0 w-full z-40">
      <div className="relative w-full h-[100vh] bottom-0 flex items-end justify-center th:items-center">
        <div
          className={classNames(
            "fixed z-20 top-0 left-0 w-full h-[100vh] bg-[rgba(0,0,0,0.25)]",
            shouldSlideOff ? "fadeOut" : "fadeIn"
          )}
          onClick={handleClose}
        />

        <div
          className={classNames(
            shouldSlideOff ? "slideOutDown" : "slideInUp",
            "relative z-20 max-h-[100vh] overflow-y-auto w-full bg-surface-1 text-white p-[24px] rounded-t-2xl shadow-modal th:max-w-[700px] th:rounded-b-2xl"
          )}
        >
          <div className="flex flex-row gap-[24px] items-start justify-between">
            <h3 className="font-satoshi text-satoshi-heading-sm tv:text-satoshi-heading-md">
              {title}
            </h3>
            <div
              className="w-[24px] h-[24px] tv:w-[32px] tv:h-[32px] rounded-full bg-white flex-shrink-0 flex items-center justify-center"
              onClick={handleClose}
            >
              <Icon
                name="close"
                size="16px"
                className="tv:w-[24px] tv:h-[24px] cursor-pointer"
              />
            </div>
          </div>
          <hr className="my-[24px]" />
          <div className="text-satoshi-body-md tv:text-satoshi-body-lg">
            {content}
          </div>
          <div className="mt-[34px]">
            <Button onClick={handleClose} variant="modal">
              {closeButton}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
