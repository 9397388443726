import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { defaultLanguage } from "./i18n";

export default function RedirectToLocale() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(defaultLanguage + "/");
  }, [navigate]);

  return <></>;
}
