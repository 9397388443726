import common from "./common.json";
import footer from "./footer.json";
import homePage from "./home-page.json";
import navbar from "./navbar.json";
import teamPage from "./team-page.json";
import tokenPage from "./token-page.json";

const es = {
  common,
  footer,
  navbar,
  "home-page": homePage,
  "team-page": teamPage,
  "token-page": tokenPage,
};

export default es;
