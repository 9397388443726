import classNames from "classnames";

export function Title({ children, className = "", as }) {
  return (
    <TitleGeneric
      as={as}
      className={classNames(
        "font-satoshi text-satoshi-display-md tv:text-satoshi-display-lg th:text-satoshi-display-xl desktop:text-satoshi-display-xl",
        className
      )}
    >
      {children}
    </TitleGeneric>
  );
}

export function TitleGeneric({ children, className, as = "h1" }) {
  const Component = as;
  return <Component className={className}>{children}</Component>;
}

export function TitleSpan({ children, className }) {
  return (
    <span
      className={classNames(
        "font-kaisei-med text-kaisei-display-md tv:text-kaisei-display-lg th:text-kaisei-display-xl desktop:text-kaisei-display-xl",
        className
      )}
    >
      {children}
    </span>
  );
}
