import Icon from "../icon";

export default function SocialNetworks({
  networks = [],
  gap = "24px",
  size = "",
  iconsClassName,
}) {
  return (
    <div className={`flex items-center gap-[${gap}]`}>
      {networks.map((n) => (
        <a
          key={n.network}
          href={n.url}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Icon className={iconsClassName} name={n.network} size={size} />
        </a>
      ))}
    </div>
  );
}
