import webedia from "../../../static/svg/logos/webedia.svg";
import kfund from "../../../static/svg/logos/kfund.svg";
import polygon from "../../../static/svg/logos/polygon.svg";
import moonpay from "../../../static/svg/logos/moonpay.svg";
import easo from "../../../static/svg/logos/easo.png";

export default function PartnersList() {
  return (
    <div className="flex flex-col mh:flex-row mh:flex-wrap items-center justify-center gap-[64px]">
      <a href="https://es.webedia-group.com/" target="_blank">
        <img width={200} height={35} src={webedia} alt="Webedia" draggable="false" />
      </a>
      <a href="https://www.kfund.vc/" target="_blank">
        <img width={120} height={36} src={kfund} alt="kfund" draggable="false" />
      </a>
      <a href="https://polygon.technology/" target="_blank">
        <img width={200} height={40} src={polygon} alt="Polygon" draggable="false" />
      </a>
      <a href="https://moonpay.com/" target="_blank">
        <img width={200} height={36} src={moonpay} alt="MoonPay" draggable="false" />
      </a>
      <a href="https://www.easoventures.com/" target="_blank">
        <img width={64} height={64} src={easo} alt="Easo Ventures" draggable="false" />
      </a>
    </div>
  );
}
