import classNames from "classnames";

export default function StaticBackground({ image, className }) {
  return (
    <div
      className={classNames(
        "absolute z-50 top-0 left-0 w-full h-full",
        className
      )}
      style={{ clip: "rect(0, auto, auto, 0)" }}
    >
      <div
        className="bg-no-repeat top-0 left-0 block fixed w-full h-full bg-fixed bg-cover"
        style={{
          transform: "translateZ(0)",
          willChange: "transform",
          backgroundImage: `url(${image})`,
          backgroundPosition: "top center",
        }}
      />
    </div>
  );
}
