import { useState } from "react";
import { Trans } from "react-i18next/TransWithoutContext";
import { useTranslation } from "react-i18next";
import Button from "../../../components/shared/button";
import Panel from "../../../components/shared/panel";
import Section from "../../../components/shared/section";
import { Title } from "../../../components/shared/title";
import ImagePanel from "../../../components/shared/imagePanel";
import SuperCommunityModal from "./superCommunityModal";
import TokenInfoModal from "./tokenInfoModal";
import useMediaQuery from "../../../hooks/useMediaQuery";
import supercommunitiesImage from "../../../static/images/supercommunities.png";
import { TitleSpan } from "../../../components/shared/title";
import FadeInUp from "../../shared/animations/fadeInUp";
import FadeIn from "../../shared/animations/fadeIn";

export default function CoffeAndCommunity() {
  const { t, ready, i18n } = useTranslation("home-page");
  const [activeModal, setActiveModal] = useState("");
  const isMobile = useMediaQuery("(max-width: 599px)");

  if (!ready) return null;

  const handleCloseModal = () => setActiveModal("");

  return (
    <>
      <Section>
      <header className="mt-[120px] mb-[120px] w-[100%]">
          <FadeIn>
            <Title>
              <Trans
                i18nKey="h1"
                ns="home-page"
                i18n={i18n}
                components={[<TitleSpan key="0">super-community</TitleSpan>]}
              />
            </Title>
          </FadeIn>
        </header>
        <div className="flex flex-col gap-[24px] tv:gap-[40px] th:gap-[64px]">
          <FadeIn>
            <ImagePanel
              image={supercommunitiesImage}
              title={t("panel-1.title")}
              content={t("panel-1.content")}
              modalButton={
                <Button onClick={() => setActiveModal("superCommunities")}>
                  {t("panel-1.button")}
                </Button>
              }
            />
          </FadeIn>

          {/* <FadeInUp>
            <Panel
              title={t("panel-2.title")}
              elements={[
                {
                  emoji: "🖼",
                  emojiBgColor: "bg-green-0",
                  text: (
                    <Trans
                      i18nKey="panel-2.items.0"
                      ns="home-page"
                      i18n={i18n}
                      components={{ b: <strong /> }}
                    />
                  ),
                },
                {
                  emoji: "😍",
                  emojiBgColor: "bg-red-0",
                  text: (
                    <Trans
                      i18nKey="panel-2.items.1"
                      ns="home-page"
                      i18n={i18n}
                      components={{ b: <strong /> }}
                    />
                  ),
                },
                {
                  emoji: "💎",
                  emojiBgColor: "bg-blue-0",
                  text: (
                    <Trans
                      i18nKey="panel-2.items.2"
                      ns="home-page"
                      i18n={i18n}
                      components={{ b: <strong /> }}
                    />
                  ),
                },
                {
                  emoji: "🔑",
                  emojiBgColor: "bg-yellow-0",
                  text: (
                    <Trans
                      i18nKey="panel-2.items.3"
                      ns="home-page"
                      i18n={i18n}
                      components={{ b: <strong /> }}
                    />
                  ),
                },
              ]}
              modalButton={
                <Button onClick={() => setActiveModal("tokenInfo")}>
                  {isMobile ? t("panel-2.buttonMobile") : t("panel-2.button")}
                </Button>
              }
            />
          </FadeInUp> */}
        </div>
      </Section>
      {activeModal === "superCommunities" && (
        <SuperCommunityModal onClose={handleCloseModal} />
      )}
      {activeModal === "tokenInfo" && (
        <TokenInfoModal onClose={handleCloseModal} />
      )}
    </>
  );
}
