import Section from "../../../components/shared/section";
import Slider from "../../../components/shared/slider";
import { Title } from "../../../components/shared/title";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next/TransWithoutContext";
import { PROJECTS } from "./constants";
import { TitleSpan } from "../../../components/shared/title";
import FadeInUp from "../../shared/animations/fadeInUp";

export default function OurProjects({ lang }) {
  const { i18n, ready } = useTranslation("home-page");

  if (!ready) return null;

  return (
    <Section className="mb-[200px]">
      <div className="mt-[88px] mb-[82px]">
        <FadeInUp>
          <Title as="h2" className="mh:text-center">
            <Trans
              i18nKey="h2"
              ns="home-page"
              i18n={i18n}
              components={[<TitleSpan key="0">proyectos</TitleSpan>]}
            />
          </Title>
        </FadeInUp>
      </div>

      <div className="flex flex-col gap-[24px] tv:gap-[40px] th:gap-[64px]">
        {PROJECTS.map((project) => (
          <FadeInUp key={project.host}>
            <Slider tNameSpace="home-page" {...project} />
          </FadeInUp>
        ))}
      </div>
    </Section>
  );
}
